// page
.cr-page {
  // min-height: 100vh;
  max-height: 100%;

  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }

    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('light');
  }

  .row {
    // stylelint-disable-next-line
    .col,
    > [class^='col-'] {
      margin-bottom: 1rem;
    }
  }
}

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  transition: 0.3s;
  width: 10px;
  // height: 30px;
  // height: calc(100vh - 75px - 50px);
}

::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px grey;
  background-color: transparent;
  border-radius: 0px;
  // height: calc(100vh - 75px - 50px);
}

::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 20px;
  // height: calc(100vh - 75px - 50px);

  // -webkit-box-shadow: inset 0 0 6px grey;
}

.ck-toolbar_grouping {
  border-radius: 32px 32px 0 0 !important;
  background-color: #e6e6e6 !important;
}

.ck-content {
  border-radius: 0 0 32px 32px !important;
  background-color: #e6e6e6 !important;
  min-height: 100px;
}

.ck-toolbar__items {
  justify-content: space-evenly;
  font-size: 0.7rem;
}

select:invalid {
  font-style: Arial !important;
  color: grey !important;
}

option {
  color: black;
  font-style: normal;
}

.rmsc .dropdown-heading .dropdown-heading-value,
option,
.col-md-12 > .form-control {
  font-style: Arial !important;
  color: #000 !important;
  font-size: 0.7rem !important;
}

.css-qc6sy-singleValue {
  font-style: Arial !important;
  color: grey !important;
}

.task-detail-desc {
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1280px) {
  .rep-container-1 {
    width: 100% !important;
  }

  .rep-container-2 {
    width: 100% !important;
  }

  .adminrep-body {
    flex-direction: column !important;
    max-width: 60rem !important;
  }

  .rep-right {
    position: unset !important;
    border: solid #e6e6e6 2px !important;
    border-radius: 32px !important;
  }
}

@media screen and (max-width: 1720px) {
  .smScreenWidth{
    width: 130% !important;
  }
  .dayName {
    max-width: 100px !important;
    padding-right: 0px !important;
  }

  .timeNme {
    max-width: 200px !important;
    padding-right: 0px !important;
    flex: 1 0 28% !important;
  }

  .hourTime {
    max-width: 100px !important;
    padding-right: 0px !important;
  }
}
// @media screen and (max-width: 1700px) {
// .smScreenWidth{
//   width: 130% !important;
// }
// }

@media screen and (max-width: 1500px) {
  .adhocImage,
  .admin-svg {
    display: none !important;
  }

  .adminrep-body {
    flex-direction: column !important;
    max-width: 50rem !important;
  }

  .rep-right {
    position: unset !important;
    border: solid #e6e6e6 2px !important;
    border-radius: 32px !important;
  }
}

@media screen and (max-width: 767px) {
  .rep-container-2 {
    position: unset;
    border: solid #e6e6e6 2px;
    border-radius: 32px;
  }

  .taskman-black-border {
    border: none !important;
  }

  .adhoc-avalinfo1 {
    padding-right: 0 !important;
  }

  .adhoc-avalinfo2 {
    margin-top: 1rem;
    padding-left: 0 !important;
  }

  .exposmall {
    text-align: left !important;
  }
}

@media screen and (max-width: 555.5px) {
  .taskman-flex-adjust {
    flex-direction: column !important;
  }

  .taskman-margin-adjust1 {
    margin-left: 0 !important;
  }

  .taskman-margin-adjust2 {
    margin: 0px 10px 16px 25px !important;
  }

  .greyBanner {
    width: 90%;
  }

  .card-header {
    font-size: x-large;
  }

  .col-md-6,
  .col-md-12 {
    padding: 0 !important;
  }

  .supplier-details {
    padding: 20px;
  }

  .supplier-contact,
  .document-upload,
  .supplier-call-cycle {
    margin-left: 8px;
    width: 95%;
  }

  .adhoc-box {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

// @media screen and (max-width: 750px) {
//   .exposmall{
//     margin-right:'0px' !important;
//   }
// }
tr:first-child th:last-child {
  border-bottom: 1px solid #f0f0f0 !important;
}

.ant-pagination-item-link > .anticon svg {
  margin-bottom: 6px;
}

.ant-table-column-title {
  text-align: center !important;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}

.ant-table-tbody > tr > td {
  text-align: center !important;
}

.rep-container-1-inner {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 30px;
}

@media screen and (max-width: 1280px) {
  .rep-container-2 {
    position: absolute;
    float: left;
    right: 39px;
    width: 100%;
    max-height: 560px;
    border-top: solid #e6e6e6 2px;
    border-right: solid #e6e6e6 2px;
    border-bottom: solid #e6e6e6 2px;
    border-radius: 0px 32px 32px 0px;
    padding: 38px 40px 0px 40px;
    overflow-y: scroll;
  }

  .rep-container-22 {
    position: absolute;
    float: left;
    right: 42px;
    width: 100%;
    // height: 584px;
    border-top: solid #e6e6e6 2px;
    border-right: solid #e6e6e6 2px;
    border-bottom: solid #e6e6e6 2px;
    border-radius: 0px 32px 32px 0px;
    padding: 20px 40px 36px 40px;
    overflow-y: scroll;
  }
}

.rmsc .dropdown-heading .dropdown-heading-value {
  margin-top: 5px;
}
.emp select:invalid {
  color: gray !important;
}
