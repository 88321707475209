select:invalid {
  font-style: Arial !important;
  color: grey !important;
}

.cr-sidebar {
  // @include media-breakpoint-down(md) {
  //   display: none;
  // }

  position: fixed;
  top: 0;
  bottom: 0;
  left: -$cr-sidebar-width;
  z-index: 5;
  width: 50px;
  // width: $cr-sidebar-width;
  font-weight: 200;
  color: #fff;
  /* font-weight: 400; */
  font-size: 1.09rem;

  background-position: center center;
  background-size: cover;
  // transition: width 0.4s ease-in !important;
  // transition: left 0.3s ease-in;

  + .cr-content {
    transition: margin 0.1s ease-in;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    background: #d71920;
    opacity: 0;
  }

  &::after {
    z-index: 3;
    // sublime light
    background: #d71920;
    opacity: 1;
    border: solid #d71920 1px;
    // border-radius: 0px 32px 32px 0px;
  }

  &[data-image]:after {
    opacity: 0.77;
  }

  &__content {
    position: relative;
    z-index: 4;
    height: calc(100vh - 20px);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  &__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__nav-item-icon {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.5rem;
  }

  &--open {
    left: 0;
    transition: left 0.2s ease-in;

    + .cr-content {
      margin: 0 0 0 $cr-sidebar-width;
      transition: margin 0.2s ease-in;
    }
  }

  .nav {
    margin-top: 20px;

    // .nav-item {
    //   margin: 5px 10px;
    //   // color: theme-color(light);
    //   color: black;
    //   // height: 50px;
    //   border-radius: 32px;
    //   // opacity: 0.86;
    //   border: solid 1px white;
    //   background: white;

    // }

    .nav-link {
      // @include media-breakpoint-down(md) {
      //   padding: .5rem;
      // }
      color: #fff;
      padding: 14px 20px;

      // &:hover {
      //   background: rgba(theme-color(light), 0.15);
      // }
    }
  }
}

.cr-sidebar[removeBorderRadius]::after {
  border-radius: 0px 32px 32px 0px;
}

.nav-item {
  margin: 15px 10px;
  // color: theme-color(light);
  color: black;
  height: 60px;
  // opacity: 0.86;
  // sborder: solid 1px white;
  background: white;
}

.square-border .nav-link.active {
  border-radius: 32px;
  background: black;
  height: 60px;
}

.square-border {
  border-radius: 32px;
}

.MuiTab-textColorInherit {
  color: #fff !important;
}

.nav-item .nav-link.active {
  background: black !important;
}

.nav-item .nav-link.active span {
  color: white;
}

.nav-item .nav-link.active svg {
  color: white !important;
}

.h1 {
  font-size: 2rem;
}

.Forpas {
  color: rgb(69, 69, 69);
  float: right;
  // margin-top: .3rem;
}

.Forpas:hover {
  cursor: pointer;
  border-bottom: solid black 1px;
  text-decoration: none;
  color: rgb(69, 69, 69);
}

.btn-block {
  width: 35%;
  border-radius: 25px;
  margin: auto;
}

.card-header {
  background-color: white;
  color: black;
  border-bottom: 0 solid transparent;
}

.card {
  border: 0px solid transparent;
}

.card-body {
  padding: 50px 68px 50px 68px;
  border-radius: 15px;
  display: cover;
  max-height: 100%;
}

.login-card {
  opacity: 0.8;
  transition: 0.3;
}

.login-card:hover {
  opacity: 1;
  transition: 0.3s;
}

.login-card:active,
.login-card:focus {
  opacity: 1;
}

button {
  background: #fff;
  border: 0px solid transparent;
  margin-right: 10px;
}

.textBox {
  padding: 1.375rem 0.75rem;
  border-radius: 1.25rem;
  width: 100%;
}

.MuiDialogTitle-root {
  background-color: rgb(69, 69, 69);
  color: #fff;
}

.MuiPaper-rounded {
  border-radius: 15px !important;
}

#tabCol,
#tabCol1,
.card-body,
.card {
  background: #fff !important;
}

#tabCol label {
  color: #000;
  font-size: 14px;
}

#tabCol .MuiPaper-rounded {
  border-radius: 15px !important;
}

#tabCol .MuiDialogTitle-root {
  background: #f85032 linear-gradient(180deg, #f96a51, #f85032) repeat-x !important;
  color: rgb(69, 69, 69);
  border-radius: 32px !important;
}

.table thead th,
.table td {
  border-top: 0px solid #1b1b1b;
  border-bottom: 0px solid #1b1b1b;
  padding: 9px 20px !important;
  text-align: left;
  box-shadow: 0px 0px 0px 1px rgba(45, 44, 44, 0.2);
}

#tabCol .MuiAppBar-colorPrimary {
  background: #646464 !important;
  color: rgb(69, 69, 69) !important;
  border-radius: 20px !important;
}

#tabCol .MuiTab-textColorInherit.Mui-selected {
  background: #f85032 linear-gradient(180deg, #f96a51, #f85032) repeat-x !important;
  color: #fff;
  border-radius: 20px;
  // border:1px solid #000 !important;
}

#tabCol .MuiTabs-indicator {
  background-color: transparent !important;
}

#tabCol button:focus {
  outline: none !important;
}

#tabCol .MuiAppBar-root {
  width: 650px;
  margin: auto;
}

.hrLine {
  width: 0px;
  border: 1px solid silver;
  height: 119px;
  margin-top: 7%;
}

.table th,
.table td {
  font-size: 14px;
  background-color: #fff !important;
  color: #000;
}

.table {
  border-radius: 7px !important;
}

#expensetable label {
  font-size: 14px;
}

// .form-check-input {
//    margin-top: 0 !important;
// }
input:-internal-autofill-selected {
  background-color: #4b4b4b !important;
}

.hrLine:nth-child(6) {
  border: 0px solid silver;
}

.profileDiv {
  padding: 2%;
  background-color: #fff;
  border-radius: 15px;
}

.profileDiv label b {
  font-weight: 600;
  font-size: 17px;
}

#familyTable thead th,
#familyTable td,
#familyTable1 thead th,
#familyTable1 td,
#familyTable2 thead th,
#familyTable2 td {
  border-top: 0px solid #1b1b1b;
  border-bottom: 0px solid #1b1b1b;
  color: #fff;
  font-size: 14px;
}

#familyTable td button,
#familyTable1 td button,
#familyTable2 td button {
  color: #fff;
}

#cardPop .card-body {
  padding: 1.25rem !important;
}

#deletepop .MuiDialog-paperWidthSm,
#deletepopfir .MuiDialog-paperWidthSm {
  width: 45%;
}

.backimgg {
  position: fixed;
  right: -33%;
  top: 5%;
  z-index: 1;
}

.hidden {
  display: none;
}

.memberstatus {
  background: rgb(69, 69, 69);
  border-radius: 15px;
  padding: 2px;
}

.memberstatus label {
  background: rgb(69, 69, 69);
  padding: 3% 6%;
  padding-top: 4%;
  color: #fff;
  margin-bottom: 0rem !important;
}

.activeStatus {
  background: #f85032 linear-gradient(180deg, #f96a51, #f85032) repeat-x !important;
  border-radius: 15px;
  width: 27%;
  color: rgb(69, 69, 69) !important;
}

.card-header {
  font-size: 36px;
  font-weight: normal;
  // font-weight: 500;
}

.MuiTab-wrapper {
  font-size: 12px;
}

// @include gradient-y(#02aab0, #00cdac); // green beach
// @include gradient-y(#348f50, #56b4d3); // emerald water
// @include gradient-y(#4CB8C4, #3CD3AD); // sea weed

// stripe
// background: #1fa2ff; /* fallback for old browsers */
// background: linear-gradient(
//   to bottom,
//   #1fa2ff,
//   #12d8fa,
//   #a6ffcb
// );

// subu
// background: #0cebeb; /* fallback for old browsers */
// background: -webkit-linear-gradient(
//   to bottom,
//   #29ffc6,
//   #20e3b2,
//   #0cebeb
// ); /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(
//   to bottom,
//   #29ffc6,
//   #20e3b2,
//   #0cebeb
// ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// mojito
// background: #1d976c; /* fallback for old browsers */
// background: -webkit-linear-gradient(
//   to bottom,
//   #93f9b9,
//   #1d976c
// ); /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(
//   to bottom,
//   #93f9b9,
//   #1d976c
// ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
.martop {
  align-items: center;
}

.martop button {
  padding: 11px;
  font-size: 14px;
}

// .MuiPaper-root,.css-1aquho2-MuiTabs-indicator{
//   background: #f85032 !important;
// }
.MuiPaper-root b,
.MuiPaper-root label {
  color: #000 !important;
  font-size: 14px;
  font-family: Arial Regular;
}

// .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,.css-1q2h7u5.Mui-selected{
//   color: #D71920 !important;
// }
.tabb button.Mui-selected {
  color: #d71920 !important;
}

// .tabb button span{
//   border-bottom: 2px solid #D71920 !important;
// }

.sub-nav-item {
  background-color: #b8b8b8;
  width: 259px; //!important;
  margin: 3px 30px !important;
}

.nav-item-closed {
  float: left !important;
  margin: 3px 0px !important;
}

.signout {
  background: #d71920 !important;
  margin: 2rem 0.4rem;
}

.signout svg {
  color: #fff !important;
}

.sidebar-crl-btn svg {
  color: #fff !important;
  float: left !important;
}

.sidebar-crl-btn {
  padding: 0.375rem 2.5px !important;
}

.navbar {
  padding: 0;
}

.w3-animate-left {
  position: relative;
  animation: animateleft 0.4s;
}

@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}
