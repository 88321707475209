.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #231f20;
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.plan-modal-dialog {
  max-width: 1000px !important;
}

.doc-modal-dialog {
  max-width: 550px !important;
}

#react-confirm-alert {
  position: relative;
  z-index: 9999;
}

.disabled-option {
  color: gray !important;
}
.total-hour {
  background-color: #e6e6e6 !important;
  color: black !important;
  border-radius: 32px !important;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 0 !important;
}

.form-control:disabled {
  color: gray !important;
}

.antdTable tr td:last-child {
  // text-align: right !important;
}
.catalogue-side-bar-icon {
  width: 28px !important;
  // width: 1.9rem !important;
  height: 2rem !important;
  padding-right: 0.8rem !important;
  margin-right: 0px !important;
  color: black;
}
.catalogue-side-bar-icon-supplier {
  // width: 28px !important;
  margin-right: 2px !important;
}

.invoice-side-bar-icon {
  width: 25px !important;
  // width: 1.9rem !important;
  height: 2rem !important;
  padding-right: 0.8rem !important;
  margin-right: 5px !important;
  color: black;
}

.rangelistTable table tr td:last-child {
  text-align: left !important;
}

.adminastration-container {
  padding: 20px 5px 20px 40px !important;
}

.adminastration-container p {
  margin-bottom: 0px;
}

.view-catalogue-table table tr td:nth-child(6) {
  max-width: 350px;
}
.invoiceTable tr td:last-child {
  text-align: center !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

#file-input.custom-file-upload {
  font: 16px/32px Arial !important;
}