.cr-content {
  // @include media-breakpoint-up(lg) {
  //   padding: 0 0 0 224px;
  // }

  // @include media-breakpoint-down(md) {
  //   &::before {
  //     content:"";
  //     display: block;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     background-color: rgba(255, 255, 255, .8);
  //     z-index: 1;
  //   }
  // }

  // @include media-breakpoint-down(md) {
  //   padding: 0 0 0 68px;
  // }

  // @include media-breakpoint-down(xs) {
  //   padding: 0;
  // }

  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: auto;
}

::-webkit-input-placeholder {
  font-style: Arial;
}
select:invalid {
  font-style: Arial !important;
  color: grey !important;
}
:-moz-placeholder {
  font-style: Arial;
}

::-moz-placeholder {
  font-style: Arial;
}

:-ms-input-placeholder {
  font-style: Arial;
}
select:invalid {
  font-style: Arial !important;
  color: grey !important;
}
.emp select:invalid {
  color: gray !important;
}
