///// search-form, icon-search, input field
.cr-search-form {
  position: relative;

  &__icon-search {
    position: absolute;
    left: 8px;
  }

  &__input {
    /* stylelint-disable-next-line */
    padding-left: 35px !important;
  }
}

// Toggle design 
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #D71920;
}

input:focus+.slider {
  box-shadow: 0 0 1px #D71920;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

////// css for main heading(h4) in reatailer page

.h4-heading {
  margin-left: 1.2%;
  margin-bottom: 1%;
}

////// phone class in retailer page

.phnclass {
  font-size: 14px !important;
  border-radius: 32px !important;
  padding: 0rem 0rem 0 0.5rem !important;
  height: calc(2rem + 0.75rem + 2px) !important;
  background-color: #fff;
  border: 1px solid #ced4da;

}

.phnclass input {
  background: #E6E6E6 0% 0% no-repeat padding-box !important;
  color: black !important;
  border: 0px solid red !important;
}

////////// selectReact in Admin page

.selectReact {
  border-radius: 0.7rem;
  border-color: hsl(0, 0%, 80%);
  min-height: 40px !important;
  font-family: Arial Regular;
}